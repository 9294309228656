<template>
  <div class="h-100 w-100 d-flex align-items-center">
    <router-view class="w-100" />
  </div>
</template>

<script>
export default {
  name: "defaultLayout",
  beforeCreate: function() {
    document.body.className = "default";
  }
};
</script>

<style lang="scss">
.default {
  background: url(../assets/image/bg.jpg) no-repeat 50% fixed;
  background-size: cover;
}

.image-side {
  background: url(../assets/image/bg.jpg) no-repeat top;
  background-size: cover;
  padding: 70px 40px;
  height: 100%;
  width: 100%;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.btn-big {
  line-height: 1.5;
  letter-spacing: 0.05rem;
  padding: 10px 40px;
}
</style>